.static {
    .search-container {
        width: 60%;
        margin-left: 18%;
        height: 50%;
        position: absolute;
        bottom: 0;

        .nav__item {
            transition: all ease 0.5s;
            background-color: #8fb5b2;
            cursor: pointer;
        }

        .nav__item:hover {
            background-color: #82a4a0;
            cursor: pointer;
        }

        .search {
            position: initial;
            float: left;
            width: 100%;
            margin-bottom: 25px;

            form {
                position: relative;
            }

            .search__btn {
                min-height: 80px;
            }

            .bootstrap-tagsinput:hover {
                cursor: text;
            }
        }
        .nav {
            position: initial;
            float: left;
            width: 100%;

            .nav__list {
                max-width: 100%;
                width: 100%;

                .nav__item {
                    margin-bottom: 25px;
                    text-align: center;
                    height: 100px;

                    width: 95%;
                    float: left;
                    background-position-x: 30px;
                    background-size: 44px 54px;

                    a {
                        height: 100px;
                        line-height: 100px;
                        display: block;
                        float: none;
                        font-size: 22px;
                        font-weight: 300;
                        width: auto;
                        margin: 0;
                        padding: 0;
                    }
                }
                .nav-container {
                    width: 50%;

                    &.right {
                        float: right;

                        .nav__item {
                            float: right;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .static .search-container .nav .nav__list .nav__item {
        background-position-x: 50px;
    }
}

@media only screen and (min-width: 1600px) {
    .static {
        .search-container {
            padding-top: 50px;
            width: 90%;
            margin-left: 4%;

            .search {
                width: 60%;
                margin-left: 20%;

                .nav {
                    .nav__list {
                        .nav__item {
                            background-position: 215px top;
                        }
                    }
                }
            }

            .nav .nav__list .nav__item {
                background-position-x: 100px;
            }
        }
    }
}

@media only screen and (max-width: 1240px) and (min-width: 768px) {
    .static {
        .search-container {
            padding-top: 0px;
            width: 80%;
            margin-left: 8%;

            .search {

                .nav {
                    .nav__list {
                        .nav__item {
                            background-position: 50px center;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .static {
        .search-container {
            .search {
                margin-bottom: 10px;
            }

            width: 100%;
            margin-left: 0%;
            position: initial;

            .nav {
                .nav__list {
                    .nav-container {
                        width: 100%;

                        .nav__item {
                            width: 100%;
                            height: 75px;
                            margin-bottom: 10px;
                            background-position: 50px center;

                            a {
                                line-height: 75px;
                            }
                        }
                    }
                }
            }

        }

        .content {
            .search {
                height: auto;
                min-height: 50px;

                form {
                    padding-right: 60px;
                }
            }

            .search input, .search .bootstrap-tagsinput {
                padding-left: 0;
                padding-right: 0;
                height: auto;
                min-height: 50px;
                line-height: 2.5em;
            }

            .bootstrap-tagsinput {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                bottom: auto;
                display: inline-block;
                height: auto;
                max-height: none;

                .tag {
                    white-space: nowrap;
                }

                .tag [data-role="remove"]:after {
                    display: inline-block;
                    padding: 0 6px;
                    width: 15px;
                    height: 15px;
                    background-position: left center;
                }
            }
        }
    }
}

@media only screen and (max-height: 675px) and (min-width: 768px) {
    .static {
        .search-container {
            bottom: 150px;
        }
    }
}

@media only screen and (max-width: 965px) {
    .static {
        .search-container {
            .search {
                .nav {
                    .nav__list {
                        .nav__item {
                            background-position: 60px top;
                        }
                    }
                }
            }
        }
    }
}