.static {
    a:hover {
        cursor: pointer;
    }
    .references {
        width: 25%;
        float: left;
        ul {
            padding: 0px;
            margin: 0px;
            li {
                margin-bottom: 15px;
                text-transform: uppercase;

                &.active {
                    padding-left: 25px;
                    background: url(/build/css/frontend/img/icon-arrow-right-featured.svg) no-repeat 0px 0px;
                    background-size: 20px 20px;
                }
            }

        }
        a {
            color: #fff;
            font-weight: 400;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .page {
        width: 75%;
        float: right;
        margin-top: 75px;
        margin-bottom: 75px;
        .title {
            width: 100%;
            background-color: #ece8cf;
            color: #000;
            padding: 25px;
            font-size: 21px;
            font-family: 'Roboto Slab', 'Helvetica', 'Arial', sans-serif;
        }
        .message {
          float:left;
            width: 100%;
            background-color: #fff;
            color: #000;
            padding: 25px;
            font-size: 16px;
            font-weight: 300;
            h1, h2, h3, h4, h5 {
                padding: 0px;
                margin: 0px;
                line-height: 1;
                padding-bottom:2px !important;
            }
            h1 {
                font-size: 24px;
                font-weight: 800;
            }
            h2 {
                font-size: 20px;
                font-weight: 500;
            }
            h3 {
                font-size: 16px;;
                font-weight: 500;
                line-height: 1.425em;
                padding-bottom: 0;
            }
            h4 {
                font-size: 19px;
                font-weight: 400;
            }
            h5 {
                font-size: 18px;
                font-weight: 300;
            }
            ul {
                padding-left: 20px;

                padding-top: 5px;
                padding-bottom: 8px;

            }

            table {
                th {
                    font-weight: 300;
                    color: #b2b894;
                    padding-right: 50px;
                    td {

                    }
                }
            }

            a.featured {
                padding-left: 25px;
                background: url(/build/css/frontend/img/icon-arrow-right-purple.svg) no-repeat 0px 0px;
                background-size: 20px 20px;
            }
        }
    }
}

@media only screen and (min-width: 1240px) {
    .fixed {
        position: fixed;
        overflow-y: scroll;
        .references {
            position: fixed;
            top: 100px;
            left: 25px;
        }
        .home {
            position: fixed;
        }
        .page {
            margin-top: 100px;
            margin-right: 25px;
        }
    }
}

@media only screen and (max-width: 1240px) {
    .fixed {
        position: fixed;
        overflow-y: scroll;
    }
    .static {

        .references {
            width: 100%;
            ul {
                width: 100%;
                list-style: none;
                li {
                    width: 33%;
                    float: left;
                    height: 40px;
                }
            }
        }
        .page {
            width: 100%;
            margin-top: 0px;
        }
    }
}

@media only screen and (max-width: 550px) {
    .static {
        .references {
            ul {
                li {
                    width: 50%;
                }
            }
        }
    }
}