.static {
    .content {
        .page {
            .message {

                .contact-accepted {
                    border: 1px solid #b8638f;
                    color: #a00057;
                    padding: 50px 100px 50px 100px;
                    display: inline-block;
                    font-weight: 500;
                }

                .contact-message {
                    font-weight: 400;
                    font-size: 16px;
                    color: #222222;
                }

                float: left;

                .contact-form {
                    width: 90%;
                    float: left;

                    form {
                        float: left;
                    }

                    .gender-input {
                        .radios {
                            float: left;
                        }
                        .star {
                            margin-top: 0px !important;
                        }
                    }
                    .error-message {
                        color: #a00057;
                        font-weight: 400;
                        margin-top: 10px;
                    }

                    .star {
                        float: left;
                        padding-top: 2px;
                        padding-right: 5px;
                        color: #a00057;
                    }
                    .mandatory {
                        float: left
                    }

                    b {
                        padding-top: 5px;
                        color: #a00057;
                        font-weight: 300;
                    }
                    .contact-row {

                        padding-left: 0px;

                        .contact-info {
                            padding-left: 0px;
                        }

                        .contact-radio {
                            margin-left: 25px;
                            margin-right: 5px;
                            &.first {
                                margin-left: 0px;
                            }
                        }

                        padding-top: 0px;
                        padding-bottom: 20px;

                        .contact-label {
                            font-weight: 500;
                            padding-left: 0px;
                        }
                        .contact-group {

                            padding-left: 0px;

                            .star {
                                margin-left: 10px;
                                margin-top: 4px;
                            }

                            input, textarea {
                                border: 3px solid #ece8cf;
                            }
                            textarea {
                                resize: none;
                            }
                            .contact-button {
                                border: none;
                                color: #fff;
                                background-color: #a00057;
                                padding: 15px 45px 15px 45px;
                                font-weight: 500;

                                &:hover {
                                    background-color: #88004a;
                                    cursor: pointer !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .contact-group {
        padding-top: 5px;
    }
    .place-holder {
        display: none;
    }
}

/** Bootstrap is weird.. **/
@media (max-width: 1279px) and (min-width: 1200px) {
    .contact-content .col-lg-3,
    .contact-content .col-md-3,
    .contact-content .col-sm-3 {
        width: 25% !important;
    }
}