.static-page {
    width: 100%;
    height: 100%;

    .static {
        transition: all ease 0.5s;
        padding-left: 25px;
        padding-right: 25px;
    }
    .content {
        width: 100%;
        height: 100%;

    }
}

.home {
    width: 100%;
    position: initial;
    float: left;
    margin-bottom: 15px;
    .home-btn {
        background: url(/build/css/frontend/img/icon-home-bg.svg) no-repeat center center;
        background-size: 26px 22px;

    }
    .menu-btn {
        background: url(/build/css/frontend/img/icon-menu-bg.svg) no-repeat center center;
        background-size: 20px 20px;
    }
    a {
        height: 80px;
        width: 25px;
        margin-right: 10px;
        display: block;
        background: transparent;
        float: left;
        text-indent: -99999px;
        overflow: hidden;
        line-height: 80px;
    }
}

.static {
    width: 100%;
    height: 100%;
    background: url(/build/css/static/img/spinoza_background.png) no-repeat left center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #262626;

    .field {
        padding-left: 5%;
        padding-right: 5%;
        z-index: 15;
        position: relative;

        .quote {
            font-family: "Roboto";
            font-weight: 200;
            font-size: 28px;
            padding-right: 25%;
            line-height: 1.1;
            color: #7d9b96
        }
        .links {
            margin-top: 15px;
            a {
                color: #fff;
                font-weight: 300;
                margin-right: 20px;
            }
        }
    }
}

.buttons {
    float: left;
    width: 100%;

    .menu__buttons {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        a {
            padding-top: 20px;
            padding-bottom: 20px;
            height: auto;
            margin-bottom: 0px;
        }
    }
}

.static {
    .grouped {
        width: 100%;
        position: absolute;
        bottom: 0;

        .signature {
            z-index: 5;
            position: relative;

            img {
                max-width: 96%;

            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .static {
        .content {
            .menu__buttons {
                a {
                    line-height: 50px;
                }
            }
            .field {
                .quote {
                    font-size: 36px;
                    letter-spacing: 1px;
                    line-height: 1;
                }
            }
        }
    }
}

@media only screen and (min-width: 1240px) {
    .static {
        .field {
            padding-left: 15%;
            padding-right: 15%;
            margin-bottom: -20px;
        }
    }
}

@media only screen and (min-width: 1600px) {
    .static {
        .signature {
            text-align: center;
            img {
                max-height: 350px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .static {
        .menu__buttons {
            .btn-database-search {
                margin-top: 25px;
            }

        }
        .grouped {
            padding-right: 6%;
            .field {
                padding-right: 0px;
                .quote {
                    br {
                        display: none;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 768px) {
    .static {
        .buttons {
            margin-top: -25px;
        }
        .menu__buttons {
            a {
                line-height: 30px;
            }

            .btn-database-search {
                margin-top: 0;
            }
        }
        .grouped {
            .field {
                .quote {
                    font-size: 23px;
                }
            }
        }
    }
}